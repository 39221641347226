@import "@/style/index.scss";

.container {
  &__content {
    padding: 5vh 10vw;
    position: relative;
    @media screen and (min-width: $desktop) {
      padding: 5vh 15vw;
    }
  }
}
