@import "@/style/index.scss";

.imageSection {
  padding: 0 10vw;
  padding-top: 3vh;
  @media screen and (min-width: $desktop) {
    padding: 0vh 15vw;
    padding-top: 7vh;
  }
  &__img {
    border-radius: 1rem;
    width: 100%;
    height: 40vh;
    @media screen and (min-width: $big-tablet) {
      height: 60vh;
    }
  }
}
