@import "@/style/index.scss";

.headerWrapper {
  @include d-flex;
  justify-content: space-between;
  padding: 0 10vw;
  width: 90vw;
  margin: 0 auto;
  border-bottom-left-radius: 1.7rem;
  border-bottom-right-radius: 1.7rem;

  @media screen and (min-width: $desktop) {
    padding: 0 15vw;
  }
}
