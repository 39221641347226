@import "@/style/index.scss";

.title {
  padding: 0 2rem;
  @include d-flex-column;
  @media screen and (min-width: 750px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__h3 {
    @include small-headers;
  }

  &__h4 {
    @include below-headers;
    font-size: 1.7rem;
  }
}
