@import "@/style/index.scss";

.btn {
  @include btn($dark-blue, $white);
}
.btn_yt {
  @include btn($red, $white);
}
.btn_small {
  @include btn($dark-blue, $white);
  padding: .7rem 1rem;
  font-size: 1.2rem;
}
