@import "@/style/index.scss";

.item {
  cursor: pointer;
  transition: color, scale, 0.7s;
  will-change: color, scale;
  &:hover {
    color: $dark-blue;
    scale: 1.1;
  }
}
.hamburger {
  color: #2d2e2d;
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
}
