@import "@/style/index.scss";

.container {
  padding: 5vh 10vw;
  margin-block: 15vh;
  @include d-flex-column;
  gap: 5rem;
  @media screen and (min-width: $desktop) {
    padding: 5vh 15vw;
  }
  &__postsWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 5rem;
    justify-items: center;
    @media screen and (min-width: $big-tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    @media screen and (min-width: $desktop) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }
}
