@import "@/style/index.scss";

.headerSection {
  @include d-flex-column;
  align-items: flex-start;
  gap: 5vh;
  margin: 1vh 0;
  @media screen and (min-width: $desktop) {
    width: 70%;
  }
}
