@import "@/style/index.scss";

.similarPosts {
  margin-bottom: 15vh;
  &__posts {
    margin-top: 7vh;
    @include d-flex;
    flex-wrap: wrap;
    gap: 4rem;
  }
}
