@import "@/style/index.scss";

.contactContainer {
  @include d-flex;
  padding: 4rem 0;
  scroll-margin-top: 10rem;

  &__wrapper {
    @include d-flex-column;
    @media screen and (min-width: 750px) {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__icons {
      @media screen and (min-width: 750px) {
        @include d-flex;
        gap: 5rem;
      }
    }
  }
}

@media screen and (min-width: 750px) {
  #mail {
    padding-top: 1rem;

    &__icon {
      margin-top: 2rem;
    }
  }
}
