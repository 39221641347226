@import "@/style/index.scss";

.tableOfContents {
  position: absolute;
  background-color: $white;
  top: 10vh;
  right: 3vw;
  @include d-flex-column;
  align-items: flex-start;
  display: none;
  box-shadow: $box-shadow;
  padding: 1.5rem;
  border-radius: 0.7rem;
  @media screen and (min-width: $desktop) {
    display: flex;
  }
  &__caption {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 5vh;
    @media screen and (min-width: $tablet) {
      font-size: 1.7rem;
    }
  }
  &__listItems {
    margin-top: 2vh;
    @include d-flex-column;
    align-items: flex-start;
    gap: 0.3rem;
    &__item {
      cursor: pointer;
      font-size: 1.05rem;
      font-weight: 300;
      color: $dark-grey;
      transition: color, 0.7s;
      will-change: color;
    }
    &__item_active {
      color: $dark-blue;
    }
    &__item:hover {
      color: $dark-blue;
    }
  }
}

.tableOfContents_sticky {
  position: fixed;
  z-index: 1;
  background-color: $white;
  top: 10vh;
  right: 3vw;
  @include d-flex-column;
  align-items: flex-start;
  display: none;
  box-shadow: $box-shadow;
  padding: 1.5rem;
  border-radius: 0.7rem;
  @media screen and (min-width: $desktop) {
    display: flex;
  }
  animation: showAnimation 0.3s linear;
}

@keyframes showAnimation {
  0% {
    transform: translateX(5vw);
    opacity: 0.3;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
