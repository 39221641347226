@import "@/style/index.scss";

.postContentSection {
  width: 100%;
  margin: 10vh 0;
  &__text {
    margin-top: 5vh;
    font-size: 1.25rem;
    color: $dark-grey;
    line-height: 2rem;
    font-weight: 300;
  }
  @media screen and (min-width: $desktop) {
    width: 70%;
  }
}

.customHeading {
  color: #4caf50;
  margin: 1rem 0;
}

.customParagraph {
  color: #333;
}

.inlineCode {
  background-color: #f5f5f5;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-size: 0.9em;
}